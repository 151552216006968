import axios from "axios";

import { formatDate } from "./formatTime";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const downloadExcel = async (name, fileName) => {
  // debugger;
  const hasQuestionMark = name.indexOf("?") !== -1;
  let questionMarkAnd;
  if (hasQuestionMark) {
    questionMarkAnd = "&";
  } else {
    questionMarkAnd = "?";
  }
  const token = window.localStorage.getItem("token");
  const date = new Date();
  try {
    // It doesn't matter whether this api responds with the Content-Disposition header or not
    const response = await axios.get(
      REACT_APP_BASE_URL +`/${ name}` + `${questionMarkAnd}export=xls`,
      {
        responseType: "blob", // this is important!
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute(
      "download",
      fileName +
        // + " "
        // + formatDate(date)
        ".xls"
    ); //this is the name with which the file will be downloaded
    link.click();
    // no need to append link as child to body.
    setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
  } catch (e) {} //error handling }
};
export default downloadExcel;
